@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

//creating a custom theme for proping 
$primary:       #443894;
$green:   #28A745;
$light:         #F4F0FF;
$dark:          #2F2A6B;
$secondary:     #343a40;

$purple-100:#F4F0FF;
$purple-200:#E1D8F9;
$purple-300:#CBBBF2;
$purple-400:#AC93E6;
$purple-500:#7B58CF;
$purple-600:#694CC0;
$purple-700:#453894;
$purple-800:#2F2A6B;
$purple-900:#232150;

$btn-font-weight: 600;
$font-family-sans-serif: "Manrope";
$font-weight-lighter: 200;
$font-weight-bolder: 800;



//importing css styling
@import '~bootstrap/scss/bootstrap.scss';


.btn-light{
    color: $primary;
    background: none;
    border:none;
}

.btn-light:hover{
    color: $primary;
}

.btn-circle {
    width: 48px;
    height: 48px;
    padding: 6px 0px;
    border-radius: 2rem;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #E9ECEF;
}

.btn-circle:hover{
    background: #E9ECEF;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.primaryColor{
    color:#443894;
}

.card-title{
line-height: 150%;
font-weight: 600;
}

// .badge-pill{
//     background-color: #F0F0F0;
//     color: #000;
//     border-radius: 5rem;
//     margin-right:0.5rem;
//     font-size:0.875rem;
// }

.badge{
    font-size:0.875rem;
    margin-right:0.25rem;
}

.emptyStateImage{
    max-width: 18.75rem;
}


.bannerText{
color: $purple-100;
}

small {
font-size:0.875rem;
}


.description {
    max-width: 400px; /* Set the desired maximum width */
    word-wrap: break-word; /* Enable text wrapping */
}

.mainDescription{
    max-width: 800px; /* Set the desired maximum width */
    word-wrap: break-word; /* Enable text wrapping */
}


h1,h2,h3,h4,h5,h6{
    font-weight: 600;
}



.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }


  .rc-slider-rail {
    background-color:  $primary !important;
  }
  
  .rc-slider-track {
    background-color: $light !important;
   
  }
  
  .rc-slider-handle {
    border-color:  $primary !important;
    background-color: #ffffff !important;
    border-width: 3px !important;
    width: 22px !important;
    height: 22px !important;
    margin-top: -8px !important;
  }
  
  .text-center {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }
  
  
 